.main-form {
  width: 780px;
  margin: 10px auto;
}
/* .dYYAhb {
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px ;
}

.KHCwJ.M0FGwd {
  max-height: 25vw;
  max-width: 100vw;
  height: 192.5px;
  width: 770px;
} */

.KHCwJ {
  background-image: url(https://lh6.googleusercontent.com/UM1U0yf7GKHGyr4Y8YDWr-bcavE7jelX0VIiAJ6hH6wlDGPlw9VchxkthsgSOOwWSs0lZIhfp6JsWN_0pS-iraSX5HoQkbeXATfjPFg0VIhzsL1XL00TdksSaO57mXnADBz8fvmvC53cCoOReq8hXpiyiDLDJA);
  background-size: cover;
  background-position: center;
}

.form-sections {
  width: 95%;
  margin: 2%;
  background: white;
  border-radius: 5px;
  text-align: left;
  padding: 20px;
  margin-bottom: 10px;
}

#name-text {
  border: none;
  border-bottom: 1px dotted;
  outline: none;
}
label.file-input-button-upload {
  background-color: rgb(254, 193, 6);
}
.submit-btn {
  background: rgb(254, 193, 6);
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 15px 5px 11px;
  color: black;
  border-radius: 6px;
  text-transform: uppercase;
}
.submit-btn:hover {
  background: #d1a112;
}
div label input {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 5px;
}

label.file-input-button-upload {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 15px 5px 11px;
  color: black;
  border-radius: 6px;
}

input.form-description {
  border: none;
  border-bottom: 1px dotted black;
  width: 500px;
}

.radioOption {
  color: #202124;
  font-size: 20px;
}
.field-sections {
  margin-bottom: 1rem;
}

.supplier-heading {
  margin: 1rem 0;
}

.downloadFile {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 15px 5px 11px;
  color: black;
  border-radius: 6px;
}

.phoneNumberInput {
  width: 11rem;
}

.form-select {
  width: 190px;
  margin-top: 10px;
}

.form-group .countryCode {
  width: 300px !important;
}

@media screen and (max-width: 530px) {
  .singnature-checkbox {
    display: block !important;
  }
  .submit-factory {
    margin-top: 10px;
    margin-left: 51px;
  }
  div label input {
    width: 16px;
    height: 18px;
    margin-right: 10px;
    margin-top: 5px;
  }
  .submit-factorynew {
    margin-top: 10px;
    margin-left: -22px;
  }
  .singnature-span {
    margin-left: 0px !important;
  }
  .paragraph-signature {
    display: block !important;
    margin-left: 5px;
  }
  .review-checkbox {
    margin-left: -17px !important;
  }
}
.date-style1 {
  margin-left: 50px;
}
.date-style2 {
  margin-left: 32px;
}

.paragraph-signature {
  margin-left: 50px;
}
.review-checkbox {
  margin-left: -12px;
}
.otherWidth {
  width: 190px !important;
}

.reviewCheckbox {
  transform: scale(1.4);
  margin-top: -20px;
  margin-right: 10px;
}
