.app-container {
  position: relative;
}

.header-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 1; /* Ensure the header appears above other content */
}

.form-container {
  padding-top: 30px; /* Adjust this value to match the header's height */
  /* Add other styles as needed */
}
p,
input {
  text-align: left;
}
* {
  font-family: Arial, Helvetica, sans-serif;
}
.App {
  text-align: center;
}
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
